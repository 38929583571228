import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home.vue'
import Projects from '../views/Projects.vue'
import About from '../views/About.vue'
import Contact from '../views/Contact.vue'
import i18n from '../i18n'

Vue.use(Router)
/* Vue.use(VueMeta) */

export function beforeEach(to, from, next) {
  let language = to.params.lang;
  if(!language) {
    language = 'en'
  }
  i18n.locale = language
  next()
}

const routes = [
    {
      path: '/',
      afterEnter() { location.forceReload(true) },
      redirect:  `/${i18n.locale}/`,
      component: Home,
      props: true,
    },
    {
      path: '/:lang/',
      afterEnter() { location.forceReload(true) },
      component: {
        Home,
        render (c) { return c('router-view') }
      },
      name: '/',
      children: [
        {
          path: `/en/`,
          afterEnter() { location.forceReload(true) },
          name: '',
          component: require('../views/Home.vue').default,
          props: {
          }
/*           meta: {
              title: '',
              metaTags: [
                {
                  name: 'description',
                  content: ''
                },
                {
                  property: 'og:description',
                  content: ''
                }
              ] 
          } */
        },
        {
          path: `/es/`,
          afterEnter() { location.forceReload(true) },
          name: '',
          component: require('../views/Home.vue').default,
          meta: {
              title: ' Diseño & desarrollo aplicaciones web JG Develop',
              metaTags: [
                {
                  name: 'description',
                  content: 'Desarrollo web, Diseño de aplicaciones web personalizadas, Gestores de contenidos ,E - commerce'
                },
                {
                  property: 'og:description',
                  content: ''
                }
              ] 
          }
        }, 
        //
        {
          path: `/en/home`,
          afterEnter() { location.forceReload(true) },
          name: '',
          component: require('../views/Home.vue').default,
          props: {
          }
/*           meta: {
            title: '',
            metaTags: [
              {
                name: 'description',
                content: ''
              },
              {
                property: 'og:description',
                content: ''
              }
            ]
          } */
        },
        {
          path: `/es/home`,
          afterEnter() { location.forceReload(true) },
          name: '',
          component: require('../views/Home.vue').default,
          meta: {
            title: 'Diseño & desarrollo aplicaciones web JG Develop',
            metaTags: [
              {
                name: 'description',
                content: 'Desarrollo web, Diseño de aplicaciones web personalizadas, Gestores de contenidos ,E - commerce'
              },
              {
                property: 'og:description',
                content: ''
              }
            ]
          }
        },
//
        {
          path: '/en/projects',
          afterEnter() { location.forceReload(true)},
          name: 'projects',
          component: Projects,
          props: {
          }
        }, 
        {
          path: '/es/projects',
          afterEnter() { location.forceReload(true) },
          name: 'projects',
          component: Projects,
          props: {
          }
        }, 
//
        {
          path: '/en/about',
          afterEnter() { location.forceReload(true) },
          name: 'about',
          component: About,
          props: {
          }
        },
        {
          path: '/es/about',
          afterEnter() { location.forceReload(true) },
          name: 'about',
          component: About,
          props: {
          }
        },
//
        {
          path: '/en/contact',
/*           afterEnter() {
            location.reload(Contact)
          }, */
          name: 'contact',
          component: require('../views/Contact.vue').default,
          props: {
            key: 'compoKey',
            compoKey: 1,
          }
        },
        {
          path: '/es/contact',
/*           afterEnter() {
            location.reload(Contact)
          }, */
          name: 'contact',
          component: Contact,
          props: {
            key: 'compoKey',
            compoKey: 2,
          }
        },
    ]
  },
]

const router = new Router({
  mode: 'history',
  scrollBehavior() {
    document.getElementById('app').scrollIntoView();
  },
  base: process.env.BASE_URL,
  routes,
  i18n
})

export default router

/*


/* export default new Router({
  
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect:  `/${i18n.locale}`,
      component: Inici,
    },
    {
      path: '/:lang',
      component: {
        render (c) { return c('router-view') }
      },
      name: '/',
      children: [
          {
            path: `/`,
            name: '/',
            component: Inici,
            meta: {
                title: 'Packet Grup Page - Distribuidor de material d´embalatge',
                metaTags: [
                  {
                    name: 'description',
                    content: 'Packet Grup.'
                  },
                  {
                    property: 'og:description',
                    content: 'Packet Grup.'
                  }
                ] 
              }
          },
          {
            path: '/ca/packetgrup',
            name: '/packetgrup',
            component: Inici,
            props: true
          },
          {
            path: '/es/packetgrup',
            name: '/packetgrup',
            component: Inici,
            props: true
          },
          {
            path: '/eco-productes',
            name:  'Ecoproductes',
            component: Ecoproductes,
            children: [
              {
                path: '/ca/eco-productes/sobres-packing-list-paper',
                name: '/eco-productes/sobres-packing-list-paper',
                component: EcoproductesChild,
                props: {
                  name: 'Sobres packing list paper',
                  pathname: '/eco-productes/sobres-packing-list-paper',
                  text: 'Sobres d´enviament de documentació amb totes les mides disponibles.',
                  image: [
                    {
                      src:'sobres-packet-list.jpg'
                    },
                  ],
                }
              },
              {
                path: '/es/eco-productes/sobres-packing-list-paper',
                name: '/eco-productes/sobres-packing-list-paper',
                component: EcoproductesChild,
                props: {
                  name: 'Sobres de papel packing list',
                  pathname: '/eco-productes/sobres-packing-list-paper',
                  text: 'Sobres para el envío de documentación con todas las medidas disonibles.',
                  image: [
                    {
                      src:'sobres-packet-list.jpg'
                    },
                  ],
                }
              },

            ]
          },
          {
            path: `/${i18n.locale}`+'/contacte-packetgrup',
            name: 'Hiden component',
            component: Contacto,
          },
        ]
      }  
  ],
})



*/
