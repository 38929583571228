<template>
  <div v-if="showform">
    <transition name="fade">
      {{ $t('contacte[0].fraseformulari')}}
    </transition>
  </div>
</template>

<script>
export default {
    name: 'tenseFormIntroduction',
    props: {
        showform: Boolean,
    },
    components: {
    },
    data () {
      return {
      }
    }
}
</script>

<style>
/*  .fade-enter-active, .fade-leave-active {
    transition: opacity 3.2s;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
    transition: opacity .8s;
  } */
</style>
