<template>

   <div>
         <div class="first-level">
            <v-app-bar id="appBar"
                  app
                  shrink-on-scroll
                  height="80px"
                  flat
               >
               <v-app-bar-nav-icon @click="drawer = !drawer"
                  class="menudesktop"
                  />

         <logo />

                <div class="barra-menu">
                  <router-link :to="`/${$i18n.locale}/home`">{{$t('homemenu')}}</router-link>
                  <router-link :to="`/${$i18n.locale}/projects`">{{$t('projectsmenu')}}</router-link>
                  <router-link :to="`/${$i18n.locale}/about`">{{$t('aboutmenu')}}</router-link>
                  <router-link :to="`/${$i18n.locale}/contact`">{{$t('contactmenu')}}</router-link>
               </div> 

<!--               <div>{{this.$route.name}}

               </div> -->

               <div class="barra-idioma">

                        <v-select
                           v-model="$i18n.locale"
                           class="idioma black-text"
                           :items="idiomas"
                           item-text="text"
                           item-value="value"
                        @change="switchLocalePath(locale)"
                        >
                  <!--       <router-link
                           v-for="locale in availableLocales"
                           :key="locale.code"
                           :to="switchLocalePath(locale.code)">
                           {{ locale.name }}
                        </router-link> -->
                     </v-select>
                     

               </div>

 <!--      <v-row align="center">
                     <v-col>
                        <v-select
                           v-model="$i18n.locale"
                           class="idioma black-text"
                           :items="idiomas"
                           item-text="text"
                           item-value="value"
                           @change="setLocale"
                        >
                        </v-select>
                     </v-col>
                  </v-row> -->
            
            
            <!--  <Header /> -->

            </v-app-bar>
            <v-navigation-drawer
               class="navmenu"
               v-model="drawer"
               app
               fixed
               temporary
               overlay-opacity=".5"
            >

               <v-list dense>

<!--                   <div>
                     <v-row align="center">
                        <v-col>
                           <v-select
                              v-model="$i18n.locale"
                              class="idioma-mobile"
                              :items="idiomas"
                              item-text="text"
                              item-value="value"
                              @change="setLocale"
                           >
                           </v-select>
                        </v-col>
                     </v-row>
                  </div> -->

                  <router-link :to="`/${$i18n.locale}/`">
                     <v-list-item link>
                        <v-list-item-action>
                           <v-icon>mdi-home</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                           <v-list-item-title class="mobile-link" >
                              {{$t('homemenu')}}
                           </v-list-item-title>
                        </v-list-item-content>
                     </v-list-item>
                  </router-link>

                  <router-link :to="`/${$i18n.locale}/projects`">
                     <v-list-item link>
                           <v-list-item-action>
                              <v-icon>mdi-folder-multiple</v-icon>
                           </v-list-item-action>
                        <v-list-item-content>
                           <v-list-item-title class="mobile-link">
                              {{$t('projectsmenu')}}
                           </v-list-item-title>
                        </v-list-item-content>
                     </v-list-item>
                  </router-link>

                  <router-link :to="`/${$i18n.locale}/about`">
                     <v-list-item link>
                           <v-list-item-action>
                              <v-icon>mdi-account-circle</v-icon>
                           </v-list-item-action>
                        <v-list-item-content>
                           <v-list-item-title class="mobile-link">
                              {{$t('aboutmenu')}}
                           </v-list-item-title>
                        </v-list-item-content>
                     </v-list-item>
                  </router-link>

                  <router-link :to="`/${$i18n.locale}/contact`">
                     <v-list-item link>
                        <v-list-item-action>
                           <v-icon>mdi-email-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                           <v-list-item-title class="mobile-link">
                              {{$t('contactmenu')}}
                              <!-- <a :href="$router.resolve({name: 'Contact'}).href">{{$t('contactmenu')}}</a> -->
                           </v-list-item-title>
                        </v-list-item-content>
                     </v-list-item>
                  </router-link>
               </v-list>
            </v-navigation-drawer>
         </div>
   </div>
</template>

<script>

import { mapGetters } from 'vuex'
import logo from '@/components/elements/logo.vue'
/* import Footer from '@/components/Footer.vue' */
/* import Header from '@/components/Header.vue' */

export default {
   name: 'navigationDrawer',
   components: {
      logo,
   },
/*    props: ['drawer'], */
   /*   data () {
      return {
         // drawer: this.drawer,
      }
   }, */
   data: () => ({
      drawer: false,
      literals : [ 'english', 'castellano'],
/*       langs: [ 
         {
         value: 'en/',
         label: 'english'
         },
         {
         value: 'es/',
         label: 'castellano'
         }
      ], */
      idiomas: [
         {
         value: 'en',
         text: 'english'
         },
         {
         value: 'es',
         text: 'castellano'
         }
      ],
   }),
   computed: {
      ...mapGetters([
         'getLanguage'
      ]),
      availableLocales () {
         return this.$i18n.locales.filter(i => i.code !== this.$i18n.locale)
      }
   },
   methods: {
      scrollToTop() {
         window.scrollTo(0,0);
      },
      stop: function () {
         event.$emit('drawer', this.drawer)
      },
      setLocale(locale){
         this.$i18n.locale = locale
   /*    locale = state.language
         console.log(locale) */
         this.$router.push({
         path: `/${this.$i18n.locale}/${this.$route.name}`,
         params: { lang: locale }
      }) 
      },
      onLanguageChange () {
         this.changeLanguage({ lang: this.lang, i18n: this.$i18n.locale })
      },
   }
}
</script>

<style lang="scss">

.tail::after {
   content: '';
   width: 100px;
   height: 3px;
   top: 60px;
   background: white;
   position: absolute;
   inset: 0;
}

.v-menu {
    display: block !important;
}

.v-list-item  {
   .v-icon.v-icon{
      margin-right: 10px !important;
   }
}

.navmenu, .navmenu a {
   text-decoration: none !important;
}

.navigation-reference {
   text-align: center;
   width: 100%;
   top:5px;

   i {
      margin-right: 5%;
      transform: rotate(90deg);
      color: rgb(88, 106, 141) !important;
      animation: rotate360 2s infinite ease-in-out;
   }
   i:hover {
      color: red !important;
   }
}
.theme--light.v-btn.v-btn--icon{
       color: rgba(249, 249, 249, 0.54) !important;
}

</style>
