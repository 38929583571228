<template>
   <div>
      <navigationDrawer />
      <IntroAbout />
      <div class="container profile">
         <v-row no-gutters>
            <div id="aboutus"></div>
            <v-row class="pt-5 pb-5">
               <v-col lg="12">
                  <h2 v-html="$t('about.titleintro')"
                     data-aos="fade-in"
                     data-aos-duration="2000"
                     data-aos-delay="100"
                  ></h2>
                  <p v-html="$t('about.textintro')">
                  </p>
                  <p></p>
               </v-col>
            </v-row>
            <v-row><!--
               <v-col class="d-flex" lg="6" md="12" cols="12">

                  <v-col class="d-block" lg="12" md="12" cols="12">
                        <img src="@/assets/images/profile-image-fm.jpg" class="profile-image">
                  </v-col>

                  <v-col class="d-block profile-bloc" lg="12" md="12" cols="12">
   
                     <h3>Fjoralba Marku</h3>
                     <h4 v-html="$t('fmarkuprofiletitle')"></h4>
                     <p v-html="$t('fmarkuprofile')"> </p>
                     /* <p>
                        After graduating in 2018 with a Bachelor’s Degree in Administration and International Business trade, I
                        spent these last years working, learning and improving skills related to Digital marketing.
                     </p>
                     <p>
                        I do have several experiences especially in customer services, as a translater and content creating. I'm still
                        learning, and looking forward to improve new skills.
                     </p><p>
                        I'm also helping Javier into creating and translating some content, to provide clear and succinct content
                        writing. To expand his portofolio and moving on with new projects.
                     </p><p>
                        I provide Italian, Spanish translating and interpreting as well. Having lived for years in Italy and now in
                        Spain, I have always improved my languages skills.
                     </p><p>
                        I love travelling and learning new cultures. I can interact effectively at all social levels and across diverse
                        cultures. Networking and communication is important for me, which actually my next challenge is Social
                        Media.   
                     </p> */ 
                     <v-btn
                        class="preview"
                        elevation="3"
                        large
                        rounded
                        dark
                     >
                        {{ $t('moreinfo')}} 
                     </v-btn>
                     <p></p>
                     <a href="">
                        <v-icon>mdi-heart-pulse</v-icon>
                     </a>
                     <a href="">
                        <v-icon>mdi-facebook</v-icon>
                     </a>
                     <a href="">
                        <v-icon>mdi-linkedin</v-icon>
                     </a>
                  </v-col>
      
               </v-col>
       --> 

                  
                  <v-col md="12" sm="12" lg="4" cols="12">
                     <img src="@/assets/images/perfil-jgdevelop.jpg" class="profile-image">
                        <p class="text-center">
                        <a href="#">
                           <v-icon>mdi-unfold-more-vertical</v-icon>
                        </a>
                        <a href="https://github.com/jgascue" target="_blank"  nofollow>
                           <v-icon>mdi-github</v-icon>
                        </a>
                        <a href="https://www.linkedin.com/in/jgascue/" target="_blank" nofollow>
                           <v-icon>mdi-linkedin</v-icon>
                        </a>
                     </p>
                  </v-col>

                  <v-col sm="12"  md="12" lg="8" class="profile" cols="12">
               <!--  <h3>Javier Gascue</h3> -->
                     <h4 v-html="$t('jgascueprofiletitle')" class="second">Desarrollador Front End & Desarrollador Front End</h4>
                     <p v-html="$t('jgascueprofile')"></p>
                     <v-btn
                        class="preview"
                        elevation="3"
                        large
                        rounded
                        dark
                        color="danger"
                        :to="`/${$i18n.locale}/contact`"
                     >
                        {{ $t('moreinfo')}} 
                     </v-btn>
                     <p></p>
                   
                  </v-col>
            </v-row>
         </v-row>
      </div>
     
   </div>

</template>

<script>

import navigationDrawer from '@/components/navigationDrawer.vue'
import IntroAbout from '@/components/intros/IntroAbout.vue';

export default {
  name: 'About',
  components: {
     IntroAbout,
     navigationDrawer
  }
}
</script>

<style lang="scss">

.profile {
   overflow: hidden;
}

.profile-bloc .theme--light.v-icon {
   color: #484848;
   padding: 3px 5px;
}
.profile-bloc .theme--light.v-icon:hover {
   color: green;
   padding: 3px 5px;
}
.profile .v-icon.notranslate.mdi.theme--light {
   margin: 3px 5px;
}
ul {
   list-style-type: none;
   padding: 0;
}

li {
   display: inline-block;
   margin: 0 10px;
}

h4 {
   color: grey !important;
}


</style>