<template>

   <div id="app">
      <div id="nav">
         <navigationDrawer id="" :drawer="drawer" />
         <router-view/>
      </div>
<!--       <transition
            name="cooldown"
         > -->
<!--       </transition>  -->
   </div>

</template>

<script>

import navigationDrawer from '@/components/navigationDrawer.vue'

export default {
   name: 'Home',
   components: {
      navigationDrawer
   },
   methods: {
   },
   data: () => ({
      drawer: false,
   })
}

</script>

<style lang="scss" >

   .cooldown-enter, ,
   .cooldown-enter-active{
      animation: .75s coolB reverse alternate ease-in-out;
      transition-duration: 0.1383s;
      transition-property: all;
      animation-delay: 1100;
   }
   .cooldown-leave,
   .cooldown-leave-active {
      animation: .63s coolB reverse !important;
   }


   .fade-enter,
   .fade-enter-active{
      animation: .75s barmenu ease-in-out;
      transition-duration: 0.1383s;
      transition-property: all;
      animation-delay: 100;
   }
   .fade-leave,
   .fade-leave-active {
      animation: fadeOut 1.68s reverse ease-in-out;
      transition-duration: 0.2383s;
      opacity: 0;
   }

</style>
