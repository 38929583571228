<template>
 
    <div v-if="showform" class="content-form">

      <v-form
          id="signup-form"
          @submit.prevent="processForm"
          ref="form"
          class="gform"
          v-model="valid"
          method="POST"
          :action="this.action"
        >

        <v-row no-gutters class="boxform form-elements">
          <v-col md="12" sm="12" lg="4" cols="12">
            
            <v-text-field
              v-model="name"
              name="name"
              :counter="20"
              type="text"
              class="imputform"
              :rules="nameRules"
              :label="$t('contacte[0].nom')"
              required
            ></v-text-field>
             <!--  
              label="name" -->
            <!--    -->
            <div class="asterisc">*</div>
          </v-col>
          <v-col md="12" sm="12" lg="4" cols="12">
            <v-text-field
              v-model="email"
              name="email"
              type="email"
              required
              class="imputform"
              :label="$t('contacte[0].email')"
              :rules="emailRules"
            >
            </v-text-field>
            <div class="asterisc">*</div>
          </v-col>
          <v-col md="12" sm="12" lg="4" cols="12">
            <v-text-field
              v-model="phone"
              name="phone" 
              min=0
              max=10
              counter=10
              type="text"
              class="imputform"
              :maxlength="maxtel"
              ref="myfield"
              :label="$t('contacte[0].telefon')" 
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <div class="small-letter">
            {{ $t('contacte[0].asterisc') }} 
          </div>
        </v-row>

        <v-row no-gutters class="boxformarea">
          <v-textarea
            filled
            required
            name="comments"
            class="body-2 imputform"
            :label="$t('contacte[0].comentari')" 
          ></v-textarea>
        </v-row>
        <v-row>
          <v-col class="d-flex justify-center" md="12" sm="12" lg="12" cols="12">        
            <div class="text-center">
              <v-btn
                color="primary"
                dark
                large
                rounded
                type="submit"
                :disabled="!valid"
                @click="loader = 'loading'"
              >
                Enviar
                
              </v-btn>    
            </div>
          </v-col>   
        </v-row>

        <v-row> 
        </v-row>

        <v-row class="form-buton">
          <div class="thankyou_message"></div>
        </v-row>

          <div class="finalPage"></div>
          <br><br>

      </v-form>
    </div>
</template>

<script>

export default {
    name: 'tenseContentForm',
    props: {
      showform: Boolean,
    },
    components: {
    },
    mounted () {
      const inputElement = this.$refs.myfield.$el.querySelector('input')
      inputElement.min = 0
      inputElement.max = 10
    },
    computed: {
      rutas () {
        return this.$router.options.routes;
      },
      menu () {
        return this.$store.state.menu
      },
      counters (value) {
        return value
      },
      namedRules () {
        return      v => !!v || this.$i18n._getMessages()[this.$i18n.locale].contacte[0].rule1,
          v => (v && v.length > 3) || this.$i18n._getMessages()[this.$i18n.locale].contacte[0].rule1b
      },
    },
    watch: {
      match: 'validateField',
      max: 'validateField',
      model: 'validateField',
    },
    data () {
      return {
        allowSpaces: false,
        match: 'Foobar',
        max: 50,
        maxtel: 10,
        min: 0,
        action: 'https://script.google.com/macros/s/AKfycbzzEXBEHqpGG7c_I_P1i0iEDCiljCOemwK_Ah0uWm08UiH6GLN3b00Q8sVrH4z7faeN/exec',
        form: '#signup-form',
        name: "",
        valid: true,
        nameRules: [
          v => !!v || this.$i18n._getMessages()[this.$i18n.locale].contacte[0].rule1 ,
          v => (v && v.length > 3) || this.$i18n._getMessages()[this.$i18n.locale].contacte[0].rule1b
        ],
        email: "",
        emailRules: [
          v => !!v || this.$i18n._getMessages()[this.$i18n.locale].contacte[0].rulecorreu1 ,
          v => /.+@.+/.test(v) || this.$i18n._getMessages()[this.$i18n.locale].contacte[0].rulecorreu2
        ],
        phone: "",
        phoneRules: [
          v => !!v || this.$i18n._getMessages()[this.$i18n.locale].contacte[0].ruletelefon ,
          v => [(!isNaN(parseFloat(v)) && v >= 0 && v <= 9)] || this.$i18n._getMessages()[this.$i18n.locale].contacte[0].rulenoneed ,
        ],
        telefon: "",
        message: "",
        comentarioRules: [v => !!v || "Completa el teu comentari, si us plau"],
        }
    },
    methods: {
      validate () {
        this.$refs.form.validate()
      },
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
      validateField () {
        this.$refs.form.validate()
      },
      updateValue: function (name) {
        return name
      },
      limitValue(event) {
        const value = event.target.value
        if (String(value).length <= 10) {
          this.limitValue = value
        }
      },
      hideForm: function() {
        this.$store.commit('handleform')
      },
      processForm: function() {
          var that = this;
          var url = this.action
          var xhr = new XMLHttpRequest()
          xhr.open('POST', url)

        /*  xhr.withCredentials = false; */
        /*    this.loader = !this.loader */
        xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8")

        xhr.onreadystatechange = function() {
          if (xhr.readyState === 4 && xhr.status === 200) {
            this.form.reset();
            var formElements = this.form.querySelector(".form-elements")
            if (formElements) {
              formElements.style.display = "none"; // hide form
            }
            var thankYouMessage = this.form.querySelector(".thankyou_message")
            if (thankYouMessage) {
              thankYouMessage.style.display = "block"
            }
            if (thankYouMessage) {
              this.hideForm()
            }
          }
        }
        function getFormData(form) {
          var elements = form.elements;
          var honeypot;
          var fields = Object.keys(elements).filter(function(k) {
            if (elements[k].name === "honeypot") {
              honeypot = elements[k].value;
              return false;
            }
            return true;
          }).map(function(k) {
            if(elements[k].name !== undefined) {
              return elements[k].name;
            // special case for Edge's html collection
            } else if(elements[k].length > 0){
              return elements[k].item(0).name;
            }
          }).filter(function(item, pos, self) {
            return self.indexOf(item) == pos && item;
          })
  
          var formData = {};
          fields.forEach(function(name){
            var element = elements[name];
            
            // singular form elements just have one value
            formData[name] = element.value;

            // when our element has multiple items, get their values
            if (element.length) {
              var data = [];
              for (var i = 0; i < element.length; i++) {
                var item = element.item(i);
                if (item.checked || item.selected) {
                  data.push(item.value);
                }
              }
              formData[name] = data.join(', ');
            }
          });
         
          // add form-specific values into the data
          formData.formDataNameOrder = JSON.stringify(fields);
          formData.formGoogleSheetName = form.dataset.sheet || "responses"; // default sheet name
          formData.formGoogleSendEmail = form.dataset.email || ""; // no email by default
          return { data: formData, honeypot: honeypot };
        }



      function disableAllButtons(form) {
        var buttons = form.querySelectorAll("button");
        for (var i = 0; i < buttons.length; i++) {
          buttons[i].disabled = true;
        }
      }

      function handleFormSubmit(event) {  
        // handles form submit without any jquery
        event.preventDefault();           
        // we are submitting via xhr below
        var form = event.target;
        var formData = getFormData(form);
        var data = formData.data;

        // If a honeypot field is filled, assume it was done so by a spam bot.
        if (formData.honeypot) {
          return false;
        }
        disableAllButtons(form);
        var url = form.action
        var xhr = new XMLHttpRequest()
        xhr.open('POST', url)
       /*  xhr.withCredentials = false; */
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded")
        xhr.onreadystatechange = function() {
          if (xhr.readyState === 4 && xhr.status === 200) {
            form.reset();
            var formElements = form.querySelector(".form-elements")
            if (formElements) {
              formElements.style.display = "none"; // hide form
            }
            var thankYouMessage = form.querySelector(".thankyou_message")
            if (thankYouMessage) {
              thankYouMessage.style.display = "block"
            }
            if (thankYouMessage) {
              that.hideForm()
            }
          }
        } 
        var encoded = Object.keys(data).map(function(k) {
            return encodeURIComponent(k) + "=" + encodeURIComponent(data[k]);
        }).join('&')
        console.log(encoded);
        console.log('encoded');
        xhr.send(encoded)
   /*      this.loader = !this.loader */
      }
              function loaded() {
          // bind to the submit event of our form
          var forms = document.querySelectorAll(".gform");
          for (var i = 0; i < forms.length; i++) {
            forms[i].addEventListener("submit", handleFormSubmit, true);
          }
        }

       document.addEventListener("DOMContentLoaded", loaded, false);
      handleFormSubmit(event);
      }
    }
}


</script>

<style scoped lang="scss">

.leyenda {
  font-size: .9em;
  margin-left: 0;
  margin-top: 30px;
}

.small-letter {
  font-size: 14px;
}
.boxform {
  padding: 0 0 20px; 
}
/* .fade-enter-active, .fade-leave-active {
  transition: opacity 3.2s ;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
  transition: opacity .8s;
} */

.content-form {
  margin-top: 30px;
}

input::-webkit-outer-spin-button,

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.titol-presupost {
  text-transform: uppercase;
  font-family: 'Cabin', sans-serif;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.input-hidden {
  display: none !important;
  visibility: hidden !important;
}


/* .v-label--active .v-label > legend {
  display:none !important;
} */



</style>