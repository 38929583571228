import Vue from 'vue'
import Vuex from 'vuex'
import i18n from '../i18n'
import VueLocalStorage from 'vue-localstorage';

Vue.use(VueLocalStorage);
Vue.use(Vuex)

/*       messages: loadLocaleMessages(), */
export default new Vuex.Store({
   state: {
      scrollBehavior: { x: 0, y: 0, behavior: 'smooth' },
      showform: true,
      menu: [
         {
            path: `/${i18n.locale}/`,
            name: 'home',
            icon: 'mdi-package-variant-closed',
         },
/*          {
            path: `/${i18n.locale}/desarrollo/middleware/`,
            name: 'home',
            icon: 'mdi-package-variant-closed',
         }, */
      ]
   },
   mutations: {
      handleform (state) {
         state.showform = !state.showform
      },
      changeLanguage: (state, response) => {
         if(response) {
            state.currentLanguage = response;
         }
      },
   },
   actions: {
      getValue (value) {
         value.commit('handlevalue')
      },
      changeLanguage(lang) {
         Vue.i18n.set(lang);
         this.selectedLanguage = lang;
         this.$store.commit('changeLang', lang);
      }
   },
   modules: {
   },
   getters: {
      navmenu: state => {
         return state.navmenu
      },
      getLanguage: state => {
         return state.language
      }
   }
})
