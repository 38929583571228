<template>
   <div id="projects">

      <navigationDrawer />
      <IntroProjects />

      <div class="container">
         <div id="ourprojects"></div>
         <v-row no-gutters>
            <v-col lg="12">
               <h2 v-html="$t('projects.titleintro')"
                  data-aos="fade-in"
                  data-aos-duration="2000"
                  data-aos-delay="100"
               ></h2>
               <p v-html="$t('projects.textintro')">
               </p>
            </v-col>
         </v-row>
      </div>
      <div v-for="(project, index) in projects" :class="'item item-project'+(index++)" :key="index">
         <div class="container">

            <projectItem :project="project" />

         </div>
      </div>
   </div>
</template>

<script>

import navigationDrawer from '@/components/navigationDrawer.vue'
import projectItem from '@/components/projectItem.vue';
import IntroProjects from '@/components/intros/IntroProjects.vue';
/* import projectDescription from '@/components/projectDescription.vue'; */
/* import ModalComponent from '@/components/elements/ModalComponent.vue'; */

export default {
   name: 'Projects',
   components: {
      IntroProjects,
      projectItem,
      navigationDrawer
      /* projectDescription, */
   },
   computed: {
      projects () {
         return [
            { 
               name: 'fevend.es', 
               sides: 4,
               title: "",
               link: "https://www.fevend.es",
               text: this.$i18n.t('projects.projectFevend'),
               image: 'fevend-presentation.webp'
            }, 
            { 
               name: 'packetgrup.com',
               sides: 6,
               title: "",
               link: "https://www.packetgrup.com/",
               text: this.$i18n.t('projects.projectPacket'),
               image: 'packetgrup-presentation.webp'
            }, 
            { 
               name: 'souriredulac.com', 
               sides: 3,
               title: "",
               link: "https://www.souriredulac.com/",
               text: this.$i18n.t('projects.projectSourire'),
               image: 'sourire-presentation2.webp'
            }
         ]
      }
   },
   data () {
      return {
/* showModal: false, E-commerce de material informático y electrónica de consumo. Proyecto realizado dentro de la plataforma de Shopify, adaptación de plantilla, sincronización del catálogo, etc.*/
         dialog: true,
         notifications: false,
         sound: true,
         widgets: false,
      }
   },

}
</script>


<style lang="scss">

   .v-dialog__container {
      display: block !important;
   }
   .v-dialog__content--active {
      background: #0d0d0da8;
   }
   .standar {
      &-button {
         margin-top: 40px;

         & button.preview {
            background: linear-gradient(15deg, #0c4b8e 0, #2a80db 100%);
            // text-transform: capitalize;
            margin: 0 25px 0 0;
            color: white !important;
            font-weight: 700;
         }

         & button {
            background: transparent;
            border-color: #42b983;
            color: rgb(19, 19, 19) !important;
            text-transform: lowercase;
            margin: 0 25px 0 0;
            font-weight: 700;
            transition: all .2823s ease;

            &:hover {
               background: #42b983;
               border-color: #23cc80;
               transition: all .58s ease;
               box-shadow: 2px 2px 7px rgba( 0, 0, 0, 0.475);
               
               a {
                  color: white !important;
               }
            }
         }
      }
   }


   h2 {
      font-weight: 700;
      margin: 0 0 30px 0;
      color: #2c3e50;
   }

   .marked {
      font-weight: bold;
      text-decoration-color: rgb(81, 252, 2);
      color: purple;
   }

   .modal-enter-active {
      opacity: 0;
   }

   .modal-leave-active {
      opacity: 0;
   }

   .modal-container,
   .modal-leave-active .modal-container {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
   }

   .modal-enter-active,
   .modal-leave-active {
      transition: opacity 0.85s;
   }
   

</style>