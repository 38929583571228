<template>
    <transition name="response">
      <div v-if="!showform" class="text-center d-flex justify-center mt-5 mb-0" >
        <p v-html="$t('contacte[0].envioOk')"></p>
      </div>
    </transition>
</template>

<script>
export default {
    name: 'tenseFormResponse',
    props: {
        showform: Boolean,
    },
    components: {},
    data () {
      return {
      }
    }
}

</script>

<style>

  .response-enter-active, .response-leave-active {
    transition: opacity 3.2s;
  }

  .response-enter, .response-leave-to {
    opacity: 0;
    transition: opacity .8s;
  }

</style>