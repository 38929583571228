<template>

   <div>

      <navigationDrawer />
      <IntroHome />

      <div class="section">
         <div class="container container-white">
            <Carousel />
         </div>
      </div>

      <div class="section section-background">
         <div class="container">
            <div id="webdesign"></div>
            <TextHome />
         </div>
      </div>

 
      <Pricing />
   
   </div>

</template>

<script>

import navigationDrawer from '@/components/navigationDrawer.vue'
import TextHome from '@/components/TextHome.vue'
import IntroHome from '@/components/intros/IntroHome.vue'
/* import Carousel from '@/components/elements/Carousel.vue' */
/* import Pricing from '@/components/elements/pricing.vue' */

const Pricing = () => import('@/components/elements/pricing.vue');
const Carousel = () => import('@/components/elements/Carousel.vue');

export default {
   name: 'index',
   components: {
      TextHome,
      IntroHome,
      Carousel,
      Pricing,
      navigationDrawer
   },
}

</script>

<style lang="scss">

 
   h1 {
      margin: 30px 0;
   }

   h2 {
      color: #212529;
   }

   h3 {
      font-weight: 700;
      color:#212529;
      margin: 40px 0;
      font-size: 1.35em;
   }

   .text-center {
      text-align: center;
   }

   .intro-text {
      font-size: .9em;
   }
</style>