import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from '@/plugins/vuetify'
import AOS from 'aos'
import './assets/styles/_index.scss'
import "aos/dist/aos.css"
import i18n from './i18n'
import axios from 'axios'

Vue.config.productionTip = false

new Vue({
  el: '#app',
  data: {
    currentRoute: window.location.pathname
  },
  axios,
  computed: {
    scrollBehavior(/* to, from, savedPosition */) {
      return { x: 0, y: 0, behavior: 'smooth' }
    },
    ViewComponent () {
      const matchingView = router[this.currentRoute]
      return matchingView
        ? require('./views/' + matchingView + '.vue')
        : require('./views/404.vue')
    },
  },
  created () {
    AOS.init()
    },
    vuetify,
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount()
  window.addEventListener('popstate', () => {
  this.app.currentRoute = window.location.pathname
})