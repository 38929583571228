<template>
  <div>

  </div>
</template>
<script>


export default {
  name: 'Contacto',
  components: {  },
  props: {  },
  data: () => ({
  }),
  watch: {
  },
  computed: {
  },
  methods: {
  }
}

</script>

<style scoped lang="scss">
</style>
