import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}
/* function getStartingLocale() {
  const browserLocale = getBrowserLocale({ countryCodeOnly: true })
  if (supportedLocalesInclude(browserLocale)) {
    return browserLocale
  } else {
    return process.env.VUE_APP_I18N_LOCALE || "en"
  }
} */
/* function checkDefaultLanguage() {
  let matched = null
  let languages = Object.getOwnPropertyNames(loadLocaleMessages())
  languages.forEach(lang => {
    if (lang === navigator.language('-')[0] || navigator.userLanguage.split('-')[0]) {
      matched = lang
    }
  })
  if (!matched) {
    return navigator.language('-')[0]
  }

} */

export default new VueI18n({
/*   locale: checkDefaultLanguage(navigator.language.split('-')[0] ), */
  //locale: [`${navigator.language.split('-')[0] || navigator.userLanguage.split('-')[0] || 'es'}`],
  locale: 'es',  
  // locale: Object.getOwnPropertyNames(checkDefaultLanguage()),
  fallbackLocale: ['en','es'],
  messages: loadLocaleMessages()
})
