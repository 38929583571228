<template>
<div>
   <v-row>
      
      <v-col lg="12" cols="12">
           <div class="layer-laptop"
            data-aos="fade-in"
            data-aos-duration="550" 
            data-aos-delay="600"
         >
               <img class="web-services" src="../assets/images/servicios-web.png" alt="web services">
               <img class="web-mail" src="../assets/images/web-mail.png" alt="web mail">

         </div>
         <div class="layertop">
            <h2 class="main" v-html="$t('inicio.title')"
               data-aos="fade-in"
               data-aos-duration="550" 
               data-aos-delay="100"
            ></h2>
            <h3 v-html="$t('inicio.subtitle')"
               data-aos="fade-in"
               data-aos-duration="550" 
               data-aos-delay="200"
            >
            </h3>
            <p v-html="$t('inicio.texto')"
               data-aos="fade-in"
               data-aos-duration="550" 
               data-aos-delay="300"
            ></p>

            <v-row>
               <v-col sm="12" lg="3" cols="12" class="lista"
                  data-aos='zoom-in'
                  data-aos-duration='550'
                  data-aos-delay='800'
                  >
                     <v-icon class="icon-list">mdi-check</v-icon>  {{$t('inicio.lista1')}}
               </v-col> 
                  
               <v-col sm="12" lg="3" cols="12" class="lista"
                  data-aos='zoom-in'
                  data-aos-duration='550'
                  data-aos-delay='800'
                  >
                     <v-icon class="icon-list">mdi-check</v-icon>  {{$t('inicio.lista2')}}
               </v-col>  
               
               <v-col sm="12" lg="3" cols="12" class="lista"
                  data-aos='zoom-in'
                  data-aos-duration='order-md650'
                  data-aos-delay='1200'
                  >
                     <v-icon class="icon-list">mdi-check</v-icon>  {{$t('inicio.lista3')}}
               </v-col>  
               
               <v-col sm="12" lg="3" cols="12" class="lista"
                  data-aos='zoom-in'
                  data-aos-duration='750'
                  data-aos-delay='1500'
                  >
                     <v-icon class="icon-list">mdi-check</v-icon>  {{$t('inicio.lista4')}}
               </v-col>
            </v-row>

            <h3 v-html="$t('inicio.subtitle2')"
               data-aos="fade-in"
               data-aos-duration="550" 
               data-aos-delay="400"
            ></h3>
            <p v-html="$t('inicio.texto2')"
               data-aos="fade-in"
               data-aos-duration="550" 
               data-aos-delay="500"
            ></p>
            <p class="text-center"
            ></p>
         </div>


         <div class="text-center bar-button-carousel pa-5">
            <router-link to="#pricing" 
               class="aboutus v-btn v-btn--is-elevated v-btn--has-bg v-btn--rounded theme--light elevation-5 v-size--x-large" 
                  @click.native="scrollTo('#pricing')"
               >
               {{$t('getstarted')}}
            </router-link>
         </div>

      </v-col>
   </v-row>
   </div>
</template>

<script>
export default {
   name: 'TextHome',
   props: {
   },
   computed: {
      location: () => window.location,
   },
   methods: {
      scrollTo: function (hashtag) {
        return this.location.href = hashtag
      }
   },
   data () {
      return {
/*          lists: [ `${$t('inicio.lista')}` ] */
      }
   }
}
</script>

<style scoped>

.icon-list {
   color: #177a94 !important;
   position: absolute !important;
   right: 10px;
   top: 10px;
   opacity:.5;
   border: 2px solid #177a9480; ;
   border-radius: 50%;
}

.layertop{
   z-index: 3;
   position: relative;
}



</style>
