import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

const opts = {
    themes: {
        light: {
            primary: "orange",
        /*     secondary: colors.grey.darken1,
            accent: colors.shades.black,
            error: colors.red.accent3, */
        },
        dark: {
            primary:'#b51111',
        },
    },
    icons: {
        iconfont: 'mdiSvg'
    },
}

export default new Vuetify(opts)
