<template>
   <div>

  <!--  <a :href="`/${$i18n.locale}/`"> -->
<a href="">
      <div class="logo">
         <img alt="JG Develop logo" src="../../../src/assets/logo-JG-w.png"
            data-aos="zoom-in"
            data-aos-duration="550" 
            >

<!--   <div class="initialLetters">
            JG
         </div> -->
<!--   <img alt="JG Develop logo" src="../../src/assets/logo.png"> -->

         <div class="legend">
            Develop
         </div>
      </div>

     </a>

   </div>
</template>


<script>

   export default {
      name: 'Logo',
      props: {
      }
   }

</script>

<style lang="scss">

.logo {
   color: white;

   img {
      margin: 5px;
   }
}

</style>
