<template>

  <div id="contacto">
    <navigationDrawer />
    <IntroContact />

    <v-container id="contactform">
      <div id="formJG"></div>
      <v-row no-gutters class=" ma-5">
        <v-col lg="6" sm="12" md="6" cols="12">

        </v-col>

        <v-col lg="12" sm="12" md="6" cols="12">

          <h2 v-html="$t('contacte[0].formtitle')"
          class="text-center"
            data-aos="fade-in"
            data-aos-duration="2000"
            data-aos-delay="100"> </h2>

          <tense-form-introduction :showform="showform" />
          <tense-content-form :showform="showform" />
        </v-col>
      </v-row>
      <v-row no-gutters>
         <v-col lg="12" sm="12" md="12" cols="12">
          <!-- <tense-form-introduction :showform="showform" /> -->
          <tense-form-response :showform="showform" />
        </v-col>
      </v-row>
      


            
    </v-container>
    

  </div>
</template>
<script>


import navigationDrawer from '@/components/navigationDrawer.vue'
import IntroContact from '@/components/intros/IntroContact.vue';
import tenseFormIntroduction from '@/components/forms/tenseFormIntroduction.vue'
import tenseFormResponse from '@/components/forms/tenseFormResponse.vue'
import tenseContentForm from '@/components/forms/tenseContentForm.vue'

export default {
  name: 'Contact',

  metaInfo() {
    return { 
      metaInfo: {
        title: 'Contacte ',
        titleTemplate: ''
      },
      title: 'Contacte',
      description: 'Contacte',
    }
  },

  components: {
    navigationDrawer,
    IntroContact,
    tenseFormIntroduction,
    tenseFormResponse,
    tenseContentForm
  },
  props: {
    msg: String
  },
  data: () => ({
    loader: null,
    loading: false,
    result: "",/* 
    showform: true, */
    buttonLoading: false,
    compoKey: 0,
  }),
  watch: {
    loader () {
      const l = this.loader
      this[l] = !this[l]
      setTimeout(() => (this[l] = false), 3000)
      this.loader = null
    },
  },
  computed: {
    showform () {
      return this.$store.state.showform
    },
  },
  methods: {
    hideForm: function() {
      this.showform = !this.showform
    },
    updatePanelist (ev) {
      this.currentPanelist = ev.target.value
    },
/*     handleresponse: function (){
      if(this.result != null)
      console.log('succes')
    } */
  }
}

</script>

<style scoped lang="scss">
  h1 {
    border: 0;
  }

  #contacto {
    left: 0%;
    top: 0;
  }

  .inside-enter-active, .inside-leave-active {
    transition: opacity 3.2s;
  }

  .inside-enter, .inside-leave-to {
    opacity: 0;
    transition: opacity .8s;
  }

  .marked {
    color: blueviolet;
  }


  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
</style>
