<template>
   <div class="bar">
      <div class="bar-background">
         <v-row no-gutters>
            <v-col class="d-lg-block bar-box pa-5" xs="10" sm="10" lg="4" >
               <h1 class="text-center"
                  data-aos="fade-in"
                  data-aos-duration="850"
                  data-aos-delay="200"
               >
                  {{$t('projects.title')}}
               </h1>
               <p
                  data-aos="fade-in"
                  data-aos-duration="1350"
                  data-aos-delay="600"
                  v-html="$t('projects.tense')"
               >
               </p>
               <div class="text-center bar-button pa-5">

                  <router-link to="#ourprojects"
                     class="aboutus v-btn v-btn--is-elevated v-btn--has-bg v-btn--rounded theme--light elevation-5 v-size--x-large" 
                     @click.native="scrollTo('#ourprojects')"
                  >
                     {{$t('getstarted')}}
                  </router-link>
               </div>
            </v-col>
         </v-row>

<!--     
         <v-btn
            elevation="5"
            x-large
            rounded
         >
         {{$t('getstarted')}}
            
         </v-btn>
         <p class="intro-text">
            Specializing  development. If you're a business seeking a web presence or are looking to hire, contact me.
         </p>
 -->

         <waves /> 

      </div>
   
   </div>
</template>

<script>
import waves from '@/components/elements/waves.vue'

export default {
   name: 'IntroProjects',
   components: {
      waves
   },
   props: {
   },
   computed: {
      location: () => window.location,
   },
   methods: {
      scrollTo: function (hashtag) {
        return this.location.href = hashtag
      }
   }
}
</script>

<style scoped>



</style>