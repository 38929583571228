<template>
   <div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
      <path fill="#f3f4f5" fill-opacity="1" d="M0,288L34.3,261.3C68.6,235,137,181,206,181.3C274.3,181,343,235,411,229.3C480,224,549,160,617,154.7C685.7,149,754,203,823,213.3C891.4,224,960,192,1029,154.7C1097.1,117,1166,75,1234,53.3C1302.9,32,1371,32,1406,32L1440,32L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"></path></svg>
   </div>
</template>


<script>
   export default {
      name: 'IconBackground',
      props: {
      }
   }
</script>

<style scoped lang="scss">


</style>
