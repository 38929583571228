<template>
    <div>
        <v-row no-gutters>
            <v-col lg="12" cols="12" class="no-col">
                <h3> 
                    <!--  <slot name="title"></slot> -->
                    <!--   {{$t('projects.projectFevendTitle')}} -->
                    {{ project.name }}
                </h3>
            </v-col>

            <v-col lg="6" cols="12" class="no-col">
                <div>
                    <img :src="require('@/assets/images/portfolio/'+`${project.image}`)" class="projects-image">
                </div>
            </v-col>

            <v-col lg="6" cols="12" class="no-col">
                <div class="projects">
                    <div class="boxtitleproject">
                    </div>
                    <p>
                        {{ project.text }}
                        <!--       <slot name="text"></slot> -->
                        <!--      {{$t('projects.projectFevend')}}  -->
                        <!--     {{ project.sides }} -->
                    </p>
                    <div class="standar-button">
                        <div class="text-left">
                            <v-col cols="auto">
                                <v-btn
                                    large
                                    rounded
                                    outlined
                                >
                                    <a :href="project.link"
                                        target="blank">
                                        {{$t('visitweb')}}
                                    </a>
                                </v-btn>
                                <!-- <v-dialog
                                    max-width="600"
                                    overlay-opacity="0"
                                    overlay-color="#000000"
                                >

                               <template v-slot:default="dialog">
                                        <v-card>
                                            <v-card-text>
                                                <h2>Project</h2>
                                            </v-card-text>
                                            <v-card-actions class="justify-end">
                                                <v-btn
                                                    text
                                                    rounded
                                                    @click="dialog.value = false"
                                                >
                                                Close
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </template>-->
                                   <!--  v-slot:activator="{ on, attrs }" -->
                                   <!--  <template v-slot:activator="{ on, attrs }">
                                        <div>
                              
                                           <v-btn
                                                class="preview"
                                                elevation="3"
                                                large
                                                rounded
                                                dark
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                {{$t('Preview')}}
                                            </v-btn> 
                                        </div>
                                   </template>
                                </v-dialog> -->
                            </v-col>
                        </div>
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>

export default {
    name: 'ProjectItem',
    props: [ 'project' ],
    data () {
        return {
           image: this.project.image
        }
    }
}
</script>

<style lang="scss">

.boxtitleproject {
    overflow: hidden;
    margin: 10px 0 20px 0;
}

h3  {
    font-size: 1.23em;
    font-weight: bold;
    margin:0;
    width: 100%;
    position: relative;
    padding: 30px 0;
}

h3:after  {
    content: '';
    width: 450px;
    height: 120px;
    left: 0px;
    top: -20px;
    position: absolute;
    text-shadow: 3px 3px 1px grey;
    padding: 20px;
    transform: rotateZ(-5deg);
    display: inherit;
    z-index: -1;
    border-radius: 10px 15px;
}

</style>
