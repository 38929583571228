<template>
   <div class="content-waves">
      <div class="svg">
         <IconBackground />
      </div>
      <div class="svg2">
         <IconBackground />
      </div>
      <div class="svg3">
         <IconBackground />
      </div>
   </div>
</template>


<script>

import IconBackground from '@/components/elements/IconBackground.vue'


export default {
   name: 'Logo',
   props: {
   },
   components: {
      IconBackground
   }
}

</script>

<style>

</style>
