<template>
   <div class="bar">
      <div class="bar-background">
         <v-row no-gutters>
            <v-col class="d-lg-block bar-box pa-5" xs="10" sm="10" lg="4" >
     
               <h1 class="text-center"
                   data-aos="fade-in"
                  data-aos-duration="850"
                  data-aos-delay="200"
               >
                  {{$t('home.title')}}
               </h1>
               <p
                  data-aos="fade-in"
                  data-aos-duration="1350"
                  data-aos-delay="800"
                  >
                  {{$t('home.tense')}}
               </p>
               <div class="text-center bar-button pa-5">
                  <router-link to="#webdesign" 
                     class="aboutus v-btn v-btn--is-elevated v-btn--has-bg v-btn--rounded theme--light elevation-5 v-size--x-large" 
                     @click.native="scrollTo('#webdesign')"
                  >
                     {{$t('getstarted')}}
                  </router-link>
               </div>
            </v-col>
         </v-row>

         <waves />

      </div>

   </div>
</template>

<script>
import waves from '/src/components/elements/waves.vue'

export default {
   name: 'IntroHome',
   components: {
      waves
   },   
   computed: {
      location: () => window.location,
   },
   methods: {
      scrollTo: function (hashtag) {
        return this.location.href = hashtag
      }
   }
}
</script>

<style>

   h1 {
      color: white;
   }

</style>